/* eslint-disable react/no-danger */
import React, { PureComponent } from 'react'
import { injectIntl } from 'react-intl'
import { Helmet } from 'react-helmet'
import Alert from 'react-s-alert'
import Autosuggest from 'react-autosuggest'
import Scrollchor from 'react-scrollchor'

import 'react-s-alert/dist/s-alert-default.css'
import 'react-s-alert/dist/s-alert-css-effects/slide.css'

import { countWords, isEmail, encode, toTitleCase } from '../components/helpers/UIHelpers'
import bg from '../images/peaceful-bg.jpg'
import Clouds from '../components/Effects/Clouds'
import getFirebase from '../components/firebase/firebase'
import logo from '../images/immortalis.svg'
import PriceBox from '../components/helpers/PriceBox'
import SEO from '../components/seo'

const slugify = require('slugify')

const renderSuggestion = (suggestion, { query, isHighlighted }) => {
  const { city, link, personName, personPhoto, type } = suggestion
  const handleAction = e => {
    e.preventDefault()
    window.location.href = link
  }
  return (
    <div
      role="button"
      tabIndex="0"
      className="flex ma0 tl items-center"
      onKeyPress={e => handleAction(e)}
      onClick={e => handleAction(e)}
    >
      <div>
        <div className="br-pill bg-mid-gray w2 h2 w3-ns h3-ns mr3 overflow-hidden">
          {type === 'memorial' && (
            <div className="aspect-ratio aspect-ratio--1x1">
              <div
                className="aspect-ratio--object cover"
                style={{
                  background: `url(${personPhoto}) center`,
                }}
              />
            </div>
          )}
        </div>
      </div>
      <div className="w-100">
        <a href={link} className="f4 f3-ns no-underline b black db">
          {personName}
        </a>
        <small className="db black t ma0">{city}</small>
      </div>
    </div>
  )
}

class IndexPage extends PureComponent {
  constructor(props, context) {
    super(props, context)
    this.state = {
      contactEmail: '',
      contactMessage: '',
      contactName: '',
      isSendingContactForm: false,
      suggestions: [],
      value: '',
    }
  }

  componentDidMount() {
    const clouds = new Clouds()
    clouds.generate()
    clouds.update()
  }

  onKeyDown = event => {
    const { link } = this.state
    if (event.keyCode === 13) {
      if (link) {
        window.location.href = link
      }
    }
  }

  onChange = (event, { newValue, method }) => {
    this.setState({
      value: toTitleCase(newValue),
    })
  }

  onSuggestionsFetchRequested = value => {
    const { firestore } = getFirebase()
    const firestoreSettings = {
      timestampsInSnapshots: true,
    }
    firestore.settings(firestoreSettings)
    const searchItems = []
    const peopleRef = firestore.collection('People')
    peopleRef
      .where('personName', '>=', toTitleCase(value.value))
      .where('personName', '<=', `${toTitleCase(value.value)}z`)
      .where('available', '==', true)
      .where('private', '==', false)
      .limit(4)
      .get()
      .then(doc => {
        const promises = []
        if (doc.empty) {
          this.setState({
            suggestions: [],
          })
        } else {
          doc.docs.map((personItem, index) => {
            const { personName, city, memorial, obituaries } = personItem.data()
            const personId = personItem.id
            const sluggedName = slugify(personName, { lower: true })
            if (memorial) {
              return promises.push(
                this.searchPersonMemorial(memorial).then(memorialItem => {
                  const { personPhoto } = memorialItem.data()
                  searchItems.push({
                    city,
                    link: `/memorials/${sluggedName}/${personId}`,
                    memorialId: memorial.id,
                    personId,
                    personName,
                    personPhoto,
                    type: 'memorial',
                  })
                })
              )
            }
            if (!memorial && obituaries.length > 0) {
              searchItems.push({
                type: 'tribute',
                personName,
                city,
                link: `/tributes/${sluggedName}/${personId}`,
              })
            }
            return {}
          })
        }
        return Promise.all(promises)
      })
      .then(() => {
        this.setState({
          suggestions: searchItems,
        })
      })
  }

  searchPersonMemorial = memorial =>
    new Promise(resolve => {
      memorial.get().then(memorialDoc => {
        resolve(memorialDoc)
      })
    })

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    })
  }

  submitContactForm = e => {
    e.preventDefault()
    const $this = this
    const form = e.target
    const { formatMessage } = $this.props
    $this.setState({
      isSendingContactForm: false,
    })
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    }).then(() => {
      $this.setState({
        isSendingContactForm: false,
      })
      Alert.success(formatMessage({ id: 'contact:success' }), {
        effect: 'stackslide',
        position: 'bottom-left',
      })
      setTimeout(() => {
        Alert.closeAll()
      }, 3000)
    })
  }

  render() {
    const {
      contactEmail,
      contactMessage,
      contactName,
      isSendingContactForm,
      suggestions,
      value,
    } = this.state
    const inputProps = {
      placeholder: 'Informe o nome da pessoa',
      value,
      onChange: this.onChange,
      onKeyDown: this.onKeyDown,
      className: 'f5 f4-m f3-l w-100 bn bg-white-10 pa3 bw1 white br3 outline-0',
    }

    const { intl } = this.props
    const { formatMessage } = intl
    return (
      <>
        <SEO
          title={`Immortalis - ${formatMessage({ id: 'homepage:headline' })}`}
          keywords={[`memorial`, `tributos`, `falecimento`, 'luto']}
        />
        <Helmet>
          <meta
            name="google-site-verification"
            content="pYQ56k5aQM0TH7o-doJWWZST6cSIjMaYHFiny5JAnwc"
          />
          <meta
            name="google-site-verification"
            content="W8iITvzTSiDTqJdjZcJ4JyrQqisaJz45dMA2LevIz6A"
          />
        </Helmet>
        <Alert />
        <section
          className="flex items-center justify-between flex-wrap cover relative overflow-hidden"
          style={{ backgroundImage: `url(${bg})` }}
        >
          <div className="bird-container bird-container--one">
            <div className="bird bird--one" />
          </div>
          <div className="bird-container bird-container--two">
            <div className="bird bird--two" />
          </div>
          <div className="bird-container bird-container--three">
            <div className="bird bird--three" />
          </div>
          <div className="bird-container bird-container--four">
            <div className="bird bird--four" />
          </div>
          <div className="tc w-100 white ph3 pt5 pb4 pt6-ns pb5-ns">
            <h1 className="ma0 mb3 f3 f3-m f1-l white lh-title">
              {formatMessage({ id: 'homepage:headline' })}
            </h1>
            <div
              className="ma0 w-100 f5 f4-ns lh-copy"
              dangerouslySetInnerHTML={{ __html: formatMessage({ id: 'homepage:subheadline' }) }}
            />
            <Scrollchor to="#about" className="dib btn btn-white-secondary br-pill ph4 mt4">
              {formatMessage({ id: 'common:know_more' })}
            </Scrollchor>
          </div>
        </section>
        <Scrollchor ref={ref => (this.memorial_search = ref)} to="memorial_search" />
        <section id="memorial_search" className="bg-black white">
          <div className="mw8-ns center tc ph3 pt3 pb5 pv4-ns">
            <h2
              className="f5 f3-ns fw4 ma0 mb3 lh-title"
              dangerouslySetInnerHTML={{
                __html: formatMessage({ id: 'homepage:search_for_memorial_or_tribute' }),
              }}
            />
            <Autosuggest
              suggestions={suggestions}
              onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
              onSuggestionsClearRequested={this.onSuggestionsClearRequested}
              getSuggestionValue={suggestion => {
                this.setState({
                  link: suggestion.link,
                  personName: suggestion.personName,
                })
                return suggestion.personName
              }}
              renderSuggestion={renderSuggestion}
              shouldRenderSuggestions={this.shouldRenderSuggestions}
              inputProps={inputProps}
              alwaysRenderSuggestions={false}
            />
            {suggestions.length === 0 && countWords(value) >= 2 && value.split(' ')[1].length > 1 && (
              <div className="tl f7 fw2 absolute mt2 white-60">
                {formatMessage({ id: 'homepage:create_a' })}&nbsp;
                <a
                  href="/create-tribute/"
                  className="bg-white ph1 no-underline br2 dib lh-copy b black mr1 f7 ttc"
                >
                  {formatMessage({ id: 'common:tribute' })}
                </a>
                {formatMessage({ id: 'common:to' })}
                <span className="dib fw5 mh1">{value}</span>
                {formatMessage({ id: 'homepage:and_immortalize_your_memories' })}
              </div>
            )}
          </div>
        </section>
        <section id="about" className="mw8-ns center tc pa3 pv4-ns">
          <h2 className="f4 f3-m f2-l">{formatMessage({ id: 'homepage:what_is_immortalis' })}</h2>
          <p className="lh-copy f6 f5-m f4-l mb3">
            {formatMessage({ id: 'homepage:what_is_immortalis_definition' })}
          </p>
          <div id="publication-types">
            <h3 className="mv3 mv4-ns fw4 f5 f5-m f3-l dark-gray">
              {formatMessage({ id: 'homepage:there_are_two_options' })}
            </h3>
            <div className="flex flex-wrap">
              <div className="pa2 pa3-ns w-50-ns">
                <PriceBox
                  description={formatMessage({ id: 'homepage:tribute_definition' })}
                  example="/tributes/eliene-araujo/RR4nCtZ8D5TuayfCy1gx"
                  highlights={[
                    formatMessage({ id: 'homepage:tribute_highlights_one' }),
                    formatMessage({ id: 'homepage:tribute_highlights_two' }),
                    formatMessage({ id: 'homepage:tribute_highlights_three' }),
                    formatMessage({ id: 'homepage:tribute_highlights_four' }),
                  ]}
                  link="/create-tribute/"
                  price={`${formatMessage({ id: 'common:currency' })}${formatMessage({
                    id: 'common:tribute_price',
                  })}`}
                  title={formatMessage({ id: 'common:tribute' })}
                />
              </div>
              <div className="pa3 w-50-ns">
                <PriceBox
                  description={formatMessage({ id: 'homepage:memorial_definition' })}
                  example="/memorials/carlos-alberto-coutinho/vwbGw0fuqq5NHvjY1aEb"
                  highlights={[
                    formatMessage({ id: 'homepage:memorial_highlights_one' }),
                    formatMessage({ id: 'homepage:memorial_highlights_two' }),
                    formatMessage({ id: 'homepage:memorial_highlights_three' }),
                    formatMessage({ id: 'homepage:memorial_highlights_four' }),
                  ]}
                  link="/create-memorial/"
                  price={`${formatMessage({ id: 'common:currency' })}${formatMessage({
                    id: 'common:memorial_price',
                  })}`}
                  title={formatMessage({ id: 'common:memorial' })}
                />
              </div>
            </div>
          </div>
        </section>
        <section id="in-memoriam" className="bg-black">
          <div className="mw8-ns center tc pa3">
            <h2 className="f4 f3-m f2-l fw4 white-40">
              {formatMessage({ id: 'common:in_memoriam' })}
            </h2>
            <ul className="list ma0 pa0 flex items-center justify-center">
              <li className="ph2">
                <div className="tc w4 w5-l">
                  <a href="/memorials/ricardo-boechat/frAzfJA9qsmZj6IljXUs/">
                    <div
                      className="w4 h4 w5-l h5-l br-pill cover bg-center center"
                      style={{
                        backgroundImage:
                          'url(https://firebasestorage.googleapis.com/v0/b/immortalis.appspot.com/o/site%2Fmemorials%2FfrAzfJA9qsmZj6IljXUs%2Fd6321205992caf455add?alt=media&amp;token=4a835b4f-4111-4489-8ca3-70737ecd9d49)',
                      }}
                    />
                  </a>
                  <h3 className="f4 h2">
                    <a
                      className="white no-underline underline-hover fw4"
                      href="/memorials/ricardo-boechat/frAzfJA9qsmZj6IljXUs/"
                    >
                      Ricardo Boechat
                    </a>
                  </h3>
                </div>
              </li>
              <li className="ph2">
                <div className="tc w4 w5-l">
                  <a href="/memorials/rafael-henzel/TbKSuMxJeyUohMTbMni4/">
                    <div
                      className="w4 h4 w5-l h5-l br-pill cover bg-center center"
                      style={{
                        backgroundImage:
                          'url(https://firebasestorage.googleapis.com/v0/b/immortalis.appspot.com/o/site%2Fmemorials%2FTbKSuMxJeyUohMTbMni4%2F94fc78995f589a416904?alt=media&token=ce6fad66-5c31-4ca3-8642-2864c66f28d9)',
                      }}
                    />
                  </a>
                  <h3 className="f4 h2">
                    <a
                      className="white no-underline underline-hover fw4"
                      href="/memorials/rafael-henzel/TbKSuMxJeyUohMTbMni4/"
                    >
                      Rafael Henzel
                    </a>
                  </h3>
                </div>
              </li>
            </ul>
          </div>
        </section>
        <section id="contact" className="bg-white">
          <div className="mw8-ns center tc pa3 pv4-ns">
            <h2 className="f3 fw6">{formatMessage({ id: 'contact:headline' })}</h2>
            <p className="f5 lh-copy mw7 center mb4">
              {formatMessage({ id: 'contact:subheadline' })}
            </p>
            <form
              action="/success"
              name="contact"
              method="post"
              className="center mw6"
              data-netlify="true"
              data-netlify-honeypot="validation"
              onSubmit={this.submitContactForm}
            >
              <input type="hidden" name="form-name" value="contact" />
              <p className="dn">
                <label htmlFor="name">
                  Don’t fill this out if you&rsquo;re human:
                  <input id="validation" name="validation" />
                </label>
              </p>
              <div className="mb3 w-100">
                <div className="input">
                  <input
                    id="contactName"
                    name="contactName"
                    autoComplete="off"
                    value={contactName}
                    onChange={e => this.setState({ contactName: e.target.value })}
                    type="text"
                    className={`${contactName !== '' ? 'filled' : ''}`}
                  />
                  <label htmlFor="contactName">{formatMessage({ id: 'contact:your_name' })}</label>
                </div>
              </div>
              <div className="mb3 w-100">
                <div className="input">
                  <input
                    name="contactEmail"
                    id="contactEmail"
                    autoComplete="off"
                    value={contactEmail}
                    onChange={e => this.setState({ contactEmail: e.target.value })}
                    type="email"
                    className={`${contactEmail !== '' ? 'filled' : ''}`}
                  />
                  <label htmlFor="contactEmail">
                    {formatMessage({ id: 'contact:your_email' })}
                  </label>
                </div>
              </div>
              <div className="mb3 w-100">
                <div className="textarea">
                  <textarea
                    name="contactMessage"
                    id="contactMessage"
                    className="filled lh-copy f6"
                    defaultValue={contactMessage}
                    onChange={e => this.setState({ contactMessage: e.target.value })}
                  />
                  <label htmlFor="contactMessage">{formatMessage({ id: 'contact:message' })}</label>
                </div>
              </div>
              <div className="mb3 w-100">
                <button
                  type="submit"
                  className="btn btn-primary w-100"
                  disabled={
                    !isEmail(contactEmail) ||
                    contactName.trim() === '' ||
                    contactMessage.trim() === '' ||
                    isSendingContactForm
                  }
                >
                  {isSendingContactForm && <>Enviando Mensagem...</>}
                  {!isSendingContactForm && <>Enviar Mensagem</>}
                </button>
              </div>
            </form>
          </div>
        </section>
        <div className="relative w-100 h-auto overflow-hidden">
          <div id="viewport">
            <div id="world" />
          </div>
          <header className="tc flex flex-wrap items-center justify-center overflow-hidden z-max relative">
            <div className="mw8-ns center tc w-100 ph3">
              <img alt="Immortalis Logo" src={logo} className="w6 center mv5 mv6-ns" />
            </div>
          </header>
        </div>
      </>
    )
  }
}

export default injectIntl(IndexPage)
