import React from 'react'

const PriceBox = ({ ...props }) => {
  const { description, example, highlights, link, price, title } = props
  return (
    <div className="box tl pa3 pa4-ns">
      <div className="flex justify-between">
        <h4 className="ma0 f4 f3-ns fw4 ttc">{title}</h4>
        {example && (
          <a href={example} rel="noopener noreferrer" className="black f7 silver" target="_blank">
            Veja um exemplo
          </a>
        )}
      </div>
      <p className="lh-copy dark-gray mb0 f6 f5-ns">{description}</p>
      <div className="mv3 f7">
        <small className="ttu gray">Taxa única de</small>
        <div className="fw6 f3">{price}</div>
      </div>
      <a href={link} className="dib btn btn-primary lh-title">
        Criar&nbsp;
        {title}
      </a>
      <ul className="lh-copy pl3 ml1 mt3 mt4-ns mb0 f7 gray">
        {highlights.map((highlight, index) => {
          const bottomMargin = index < props.highlights.length - 1 ? 'mb3' : ''
          return (
            // eslint-disable-next-line react/no-array-index-key
            <li key={index} className={`${bottomMargin}`}>
              {highlight}
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default PriceBox
