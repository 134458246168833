const daysInMonth = (month, year) => new Date(year, month, 0).getDate()

// eslint-disable-next-line arrow-body-style
const getBrazilianStateNameFromUF = uf => {
  return {
    AC: 'Acre',
    AL: 'Alagoas',
    AP: 'Amapá',
    AM: 'Amazonas',
    BA: 'Bahia',
    CE: 'Ceará',
    DF: 'Distrito Federal',
    ES: 'Espírito Santo',
    GO: 'Goiás',
    MA: 'Maranhão',
    MT: 'Mato Grosso',
    MS: 'Mato Grosso do Sul',
    MG: 'Minas Gerais',
    PA: 'Pará',
    PB: 'Paraíba',
    PR: 'Paraná',
    PE: 'Pernambuco',
    PI: 'Piauí',
    RJ: 'Rio de Janeiro',
    RN: 'Rio Grande do Norte',
    RS: 'Rio Grande do Sul',
    RO: 'Rondônia',
    RR: 'Roraima',
    SC: 'Santa Catarina',
    SP: 'São Paulo',
    SE: 'Sergipe',
    TO: 'Tocantins',
  }[uf]
}

const randomString = length => {
  const random13chars = () =>
    Math.random()
      .toString(16)
      .substring(2, 15)

  const loops = Math.ceil(length / 13)
  return new Array(loops)
    .fill(random13chars)
    .reduce((string, func) => string + func(), '')
    .substring(0, length)
}

const encode = data =>
  Object.keys(data)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&')

const getStringParams = str => {
  const params = {}
  str.replace(/[?&]+([^=&]+)=([^&]*)/gi, (m, key, value) => {
    params[key] = value
  })
  return params
}

const countWords = str => str.trim().split(/\s+/).length

const slugify = text =>
  text
    .toString()
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .replace(/--+/g, '-') // Replace multiple - with single -

const toTitleCase = string => {
  let returnString = string
  returnString = string.replace(
    /([^\s:\-'])([^\s:\-']*)/g,
    txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  )
  const lowers = ['De', 'Dos', 'Da']
  for (let i = 0; i < lowers.length; i += 1) {
    returnString = returnString.replace(new RegExp(`\\s${lowers[i]}\\s`, 'g'), txt =>
      txt.toLowerCase()
    )
  }
  return returnString
}

const isEmail = email =>
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  )

const isYouTubeUrl = url => {
  const pUrl = url || ''
  // eslint-disable-next-line no-useless-escape
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/
  const match = pUrl.match(regExp)
  if (match && match[2].length === 11) {
    return true
  }
  return false
}

const range = (start, end) =>
  Array(end - start + 1)
    .fill()
    .map((_, idx) => start + idx)

export {
  countWords,
  daysInMonth,
  encode,
  getBrazilianStateNameFromUF,
  getStringParams,
  isEmail,
  isYouTubeUrl,
  randomString,
  range,
  slugify,
  toTitleCase,
}
