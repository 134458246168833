/* eslint-disable no-shadow */
/* eslint-disable no-loop-func */
/* eslint-disable no-plusplus */
import cloudImage from '../../images/cloud.png'

class Clouds {
  constructor() {
    let lastTime = 0
    const vendors = ['ms', 'moz', 'webkit', 'o']
    for (let x = 0; x < vendors.length && !window.requestAnimationFrame; ++x) {
      window.requestAnimationFrame = window[`${vendors[x]}RequestAnimationFrame`]
      window.cancelRequestAnimationFrame = window[`${vendors[x]}CancelRequestAnimationFrame`]
    }

    if (!window.requestAnimationFrame) {
      window.requestAnimationFrame = (callback, element) => {
        const currTime = new Date().getTime()
        const timeToCall = Math.max(0, 16 - (currTime - lastTime))
        const id = window.setTimeout(() => {
          callback(currTime + timeToCall)
        }, timeToCall)
        lastTime = currTime + timeToCall
        return id
      }
    }

    this.updateBound = this.update.bind(this)

    this.layers = []
    this.objects = []
    this.world = document.getElementById('world')
    this.viewport = document.getElementById('viewport')
    this.d = 0
    const p = 400
    this.worldXAngle = 0
    this.worldYAngle = 0

    this.viewport.style.webkitPerspective = p
    this.viewport.style.MozPerspective = p
    this.viewport.style.oPerspective = p
  }

  createCloud() {
    const div = document.createElement('div')
    div.className = 'cloudBase'
    let x = 256 - Math.random() * 512
    let y = 256 - Math.random() * 512
    const z = 256 - Math.random() * 512
    const t = `translateX(${x}px) translateY(${y}px) translateZ(${z}px)`
    div.style.webkitTransform = t
    div.style.MozTransform = t
    div.style.oTransform = t
    this.world.appendChild(div)

    for (let j = 0; j < 5 + Math.round(Math.random() * 10); j++) {
      const cloud = document.createElement('img')
      cloud.style.opacity = 0
      const r = Math.random()
      const src = cloudImage
      cloud.addEventListener('load', () => {
        cloud.style.opacity = 0.8
      })
      cloud.setAttribute('src', src)
      cloud.className = 'cloudLayer'

      x = 256 - Math.random() * 512
      y = 256 - Math.random() * 512
      const z = 100 - Math.random() * 200
      const a = Math.random() * 360
      const s = 0.25 + Math.random()
      x *= 0.2
      y *= 0.2
      cloud.data = {
        x,
        y,
        z,
        a,
        s,
        speed: 0.1 * Math.random(),
      }
      const t = `translateX(${x}px) translateY(${y}px) translateZ(${z}px) rotateZ(${a}deg) scale(${s})`
      cloud.style.webkitTransform = t
      cloud.style.MozTransform = t
      cloud.style.oTransform = t
      div.appendChild(cloud)
      this.layers.push(cloud)
    }
    return div
  }

  generate() {
    const objects = []
    if (this.world.hasChildNodes()) {
      while (this.world.childNodes.length >= 1) {
        this.world.removeChild(this.world.firstChild)
      }
    }

    for (let j = 0; j < 5; j++) {
      objects.push(this.createCloud())
    }
  }

  update() {
    for (let j = 0; j < this.layers.length; j++) {
      const layer = this.layers[j]
      layer.data.a += layer.data.speed
      const t = `
        translateX(${layer.data.x}px)
        translateY(${layer.data.y}px)
        rotateX(${-this.worldXAngle}deg)
        rotateY(${-this.worldYAngle}deg)
        translateZ(${layer.data.z}px)
        rotateZ(${layer.data.a}deg)
        scale(${layer.data.s})`
      layer.style.webkitTransform = t
      layer.style.MozTransform = t
      layer.style.oTransform = t
    }
    window.requestAnimationFrame(this.updateBound)
  }
}

export default Clouds

// generate()
// update()
